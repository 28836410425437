// var split = new SplitText("h1", { type: "chars", position: "absolute"});
// TweenMax.to(split.chars, 0.5, { left: 150, yoyo: true, repeat: -1, repeatDelay: 0.5});

var tl = gsap.timeline({repeat:1});

// tl.from(".animate-this", { duration: 1, y: 30, opacity: 0, stagger: 0.4, delay: 0.2 });

// // tl.from('.animate-this', {duration: 1.5, translateY: 50, opacity: 0, stagger: 0.4, delay: 0.2})
// tl.to('.animate-img', {clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)"}, "-=.1")

// var tl = new TimelineMax({repeat:-1})


tl.to('.char-box' , { rotation: 0, y:-200, stagger: 0.2, opacity:0 , scale:2,  ease:"Back.in" });
tl.to('.dot-box' , {  rotation: 0, x:-300 , stagger: 0.1, scale:5,   opacity:0 , ease:"bounce.inOut" }); 

tl.to('.char-box' , 1, {  rotation: 0, y:0 , stagger: 0.2,  opacity:1 , scale:1, ease:"Back.in" }); 
tl.to('.dot-box' , {  rotation: 0, x: 0, y: -200 , stagger: 0.1, scale:2,  opacity:1 , ease:"bounce.inOut" }); 
tl.to('.dot-box' , {  rotation: 0, x:300, y:0, stagger: 0.1, scale:1,   opacity:0 , ease:"bounce.inOut" }); 

tl.to('.char-box' , {  rotation: -0, y:100 , stagger: 0.2,  opacity:0 , scale:3, ease:"back.out" });

// tl.to('.dot-box' , {  rotation: 0, x:300 , stagger: 0.1, scale:3,   opacity:0 , ease:"Back.in" }); 
tl.to('.char-box' , {  rotation: 0, y:0 , stagger: 0.2,  opacity:1 , scale:1, ease:"Back.in" }); 

tl.to('.dot-box' , {  rotation: 0, x:0, y:0, stagger: 0.1, scale:1,   opacity:1 , ease:"bounce.inOut" }); 



// tl.to('.animate-img', 1, {left:"100px", repeat:-1});

var tl2 = gsap.timeline({repeat:2});
tl2.to('.under-box' , 5, { rotation: 0, x:-1000, y:0, stagger: 0.1, scale:1, opacity:0, ease:"bounce.inOut" }); 
tl2.to('.under-box' , 5, { rotation: 0, x:0, y:0, stagger: 0.1, scale:1,  opacity:1 , ease:"bounce.inOut" }); 
